export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  PATIENT_USER: 'ROLE_PATIENT_USER', // formarly ROLE_PATIENT_USER, but we do not have this role mapped in KC
  MANAGE_CLINIC: 'ROLE_MANAGE_CLINIC',
  VIEW_CLINIC: 'ROLE_VIEW_CLINIC',
  CREATE_CLINIC: 'ROLE_CREATE_CLINIC',
  UPDATE_CLINIC: 'ROLE_UPDATE_CLINIC',
  DELETE_CLINIC: 'ROLE_DELETE_CLINIC',
  CREATE_PATIENT: 'ROLE_CREATE_PATIENT',
  UPDATE_PATIENT: 'ROLE_UPDATE_PATIENT',
  VIEW_PATIENT_LIST: 'ROLE_VIEW_PATIENT_LIST',
  VIEW_PATIENT_DETAILS: 'ROLE_VIEW_PATIENT_DETAILS',
  CREATE_CONSULTATION: 'ROLE_CREATE_CONSULTATION',
  UPDATE_CONSULTATION: 'ROLE_UPDATE_CONSULTATION',
  MANAGE_CONSULTATION_PHOTO: 'ROLE_MANAGE_CONSULTATION_PHOTO',
  VIEW_MATCHING: 'ROLE_VIEW_MATCHING'
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'YYYY-MM-DD';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';

import React from 'react';

export const Button = ({ children, variant = 'primary', size = 'md', ...props }) => {
  const baseStyles = 'px-4 py-2 rounded shadow';
  const variantStyles = variant === 'outline' ? 'border border-blue-500 text-blue-500' : 'bg-blue-500 text-white';
  const sizeStyles = size === 'sm' ? 'text-sm' : 'text-md';

  return (
    <button className={`${baseStyles} ${variantStyles} ${sizeStyles}`} {...props}>
      {children}
    </button>
  );
};

import React, { useState, useEffect, useRef, createRef } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardFooter, CardDescription, CardContent } from '../components/ui/Card';
import { CalendarIcon, LocateIcon, UsersIcon, FileTextIcon, FileIcon } from './icons';
import { getEntities as getConsultations, getEntityReport as getReport } from 'app/entities/patient/consultation/consultation.reducer';
import { getConsultationEntities as getQuotes } from 'app/entities/patient/consultation/quotes/quote.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IConsultation } from 'app/shared/model/consultation.model';
import axios from 'axios';
import { IReport } from 'app/shared/model/report.model';
import { IQuote } from 'app/shared/model/quote.model';
import dayjs from 'dayjs';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

const ConsultationList = () => {
  const patientId = useAppSelector(state => state.authentication.account.loggedPatientInfo?.id);
  const consultations: IConsultation[] = useAppSelector(state => state.consultation.entities);
  const [reports, setReports] = useState<Map<string, IReport>>(new Map());
  const [quotes, setQuotes] = useState<Map<string, IQuote[]>>(new Map());
  const [clinics, setClinics] = useState<Map<string, string>>(new Map());
  const dispatch = useAppDispatch();

  const fetchConsultationQuotes = async (patientId: string, consultationId: string, locale: string) => {
    const requestUrl = `api/patients/${patientId}/consultations/${consultationId}/quotes`;

    try {
      const response = await axios.get<IQuote[]>(requestUrl);
      return response.data;
    } catch (error) {
      console.error('Erreur lors de la récupération des devis de consultation', error);
      throw error;
    }
  };

  const fetchConsultationReport = async (patientId: string, consultationId: string, locale: string) => {
    const requestUrl = `api/patients/${patientId}/consultations/${consultationId}/reports?language=${locale}`;
    try {
      const response = await axios.get<IReport>(requestUrl);
      console.log('response à fetchConsultationReport', response.data);
      return response.data;
    } catch (error) {
      console.error('Erreur lors de la récupération du rapport de consultation', error);
      throw error;
    }
  };

  const handleViewQuote = (consultationId, quote) => {
    window.open(`api/patients/${patientId}/consultations/${consultationId}/quotes/${quote.id}/file`, '_blank');
  };

  useEffect(() => {
    if (consultations.length === 0 && patientId) {
      const params = {
        page: 0,
        size: 20,
        sort: 'id,asc',
      };
      dispatch(getConsultations({ params, patientId }));
    }
    // Lot of workarounds to get relevant informations - to be fixed - store not usable with its current structure
    consultations.forEach(consultation => {
      // Foreach consultation, we have to use a direct call to get the report, the quotes and eventually plunge into the report to get the clinic name
      /*
      const fetchConsultationQuotes = async () => {
        const response = await dispatch(getQuotes({ patientId, consultationId: consultation.id }));
        console.log('response à fetchConsultationQuotes', response);
      };

      fetchConsultationQuotes();
      */
    });
  }, [dispatch, patientId]);

  useEffect(() => {
    const getReports = async () => {
      const newReports = new Map<string, IReport>();
      const newClinics = new Map<string, string>();
      for (const consultation of consultations) {
        const report = await fetchConsultationReport(patientId, consultation.id, 'fr');
        console.log('REPORTS IN LIST', report);
        newReports.set(consultation.id, report);
        newClinics.set(consultation.id, report.clinic?.name || 'Clinique non renseignée');
      }
      setReports(newReports);
      setClinics(newClinics);
    };

    getReports();

    const getQuotes = async () => {
      const newQuotes = new Map<string, IQuote[]>();
      for (const consultation of consultations) {
        const quotes = await fetchConsultationQuotes(patientId, consultation.id, 'fr');
        newQuotes.set(consultation.id, quotes);
      }
      setQuotes(newQuotes);
    };
    getQuotes();
  }, [patientId, consultations]);

  return (
    <section className="w-full py-12 md:py-24 lg:py-32 bg-muted flex flex-col items-center">
      <div className="container space-y-12  mx-auto">
        <div className="flex flex-col items-center justify-center space-y-4 text-center">
          <div className="space-y-2">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">Mes rendez-vous passés</h2>
            <p className="max-w-[900px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
              Restez informé de chaque consultation dentaire que vous avez eue.
            </p>
          </div>
        </div>
        <div className="grid gap-4">
          {consultations.map((consultation, index) => (
            <Card key={index}>
              <CardHeader>
                <CardTitle>Votre rendez-vous du {dayjs(consultation.date).format(APP_DATE_FORMAT)}</CardTitle>
                <CardDescription>
                  <div className="flex items-center gap-2">
                    <CalendarIcon className="h-4 w-4 text-muted-foreground" />
                    <span>{dayjs(consultation.date).format(APP_DATE_FORMAT)}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <LocateIcon className="h-4 w-4 text-muted-foreground" />
                    <span>{clinics.get(consultation.id)}</span>
                  </div>
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid gap-2">
                  <div className="flex items-center gap-2">
                    <UsersIcon className="h-4 w-4 text-muted-foreground" />
                    <span>Dr. {consultation.practitionerSnapshot}</span>
                  </div>
                  {reports.get(consultation.id) && (
                    <div className="flex items-center gap-2">
                      <FileTextIcon className="h-4 w-4 text-muted-foreground" />
                      <span>Rapport de consultation</span>
                      <Link to={`./report/${consultation.id}`} className="ml-auto">
                        <FileIcon className="h-4 w-4 text-muted-foreground" />
                      </Link>
                    </div>
                  )}
                  {quotes.get(consultation.id) &&
                    quotes.get(consultation.id).map((quote, quoteIndex) => (
                      <div className="flex items-center gap-2">
                        <FileTextIcon className="h-4 w-4 text-muted-foreground" />
                        <span>Devis: {quote.originalName}</span>
                        <div className="ml-auto cursor-pointer">
                          <FileIcon onClick={() => handleViewQuote(consultation.id, quote)} className="h-4 w-4 text-muted-foreground" />
                        </div>
                      </div>
                    ))}
                </div>
              </CardContent>
              <CardFooter>
                <Link to={`/patient-app/appointments/${consultation.id}`} className="text-sm font-medium text-primary-tailwind">
                  Voir en détails
                </Link>
              </CardFooter>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ConsultationList;

import React, { useState, useEffect } from 'react';
import { Button } from '../components/ui/Button';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/PatientFooter';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import { getEntity as getPatient, blindPartialUpdateEntity } from 'app/entities/patient/patient.reducer';

const Settings = () => {
  const patientId = useAppSelector(state => state.authentication.account.loggedPatientInfo?.id);

  const [userData, setUserData] = useState(null);
  const [formData, setFormData] = useState({
    id: patientId,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    address: '',
    socialNumber: '',
    mutuelleNumber: '',
    mutuelleName: '',
    mutuelleMail: '', // Add this line
  });

  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const patient = useAppSelector(state => state.patient.entity);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (patientId) dispatch(getPatient(patientId));
  }, [patientId]);

  useEffect(() => {
    if (patient) {
      setFormData({
        id: patient.id,
        firstName: patient.firstName || '',
        lastName: patient.lastName || '',
        phoneNumber: patient.phoneNumber || '',
        address: patient.address || '',
        socialNumber: patient.socialNumber || '',
        mutuelleNumber: patient.mutuelleNumber || '',
        mutuelleName: patient.mutuelleName || '',
        mutuelleMail: patient.mutuelleMail || '',
      });
    }
  }, [patient]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const partiaPatient = formData;
    dispatch(blindPartialUpdateEntity(partiaPatient));
    alert('Modifications enregistrées avec succès!');
  };

  if (!isAuthenticated) {
    return <div>Please Authenticate</div>;
  }

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow container mx-auto p-4">
        <h2 className="text-3xl font-bold mb-4">My informations</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Existing fields */}
            <div>
              <label className="block text-sm font-medium text-gray-700">First Name</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="mt-1 block w-full border rounded-md p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Last Name</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="mt-1 block w-full border rounded-md p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Phone</label>
              <input
                type="text"
                name="phone"
                value={formData.phoneNumber}
                onChange={handleChange}
                className="mt-1 block w-full border rounded-md p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Address</label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="mt-1 block w-full border rounded-md p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Social Security Number</label>
              <input
                type="text"
                name="socialSecurityNumber"
                value={formData.socialNumber}
                readOnly
                className="mt-1 block w-full border rounded-md p-2 bg-gray-100 border-gray-300 text-gray-500 cursor-not-allowed"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Insurance Number</label>
              <input
                type="text"
                name="insuranceNumber"
                value={formData.mutuelleNumber}
                onChange={handleChange}
                className="mt-1 block w-full border rounded-md p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Insurance Name</label>
              <input
                type="text"
                name="insuranceName"
                value={formData.mutuelleName}
                onChange={handleChange}
                className="mt-1 block w-full border rounded-md p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Insurance Email</label>
              <input
                type="email"
                name="insuranceEmail"
                value={formData.mutuelleMail}
                onChange={handleChange}
                className="mt-1 block w-full border rounded-md p-2"
              />
            </div>
          </div>
          <div>
            <Button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border rounded-md shadow-sm text-sm font-medium text-white btn-blue hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Save Changes
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Settings;
